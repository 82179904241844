<template>
  <div class="about row">
    <vxe-grid ref="xGrid" v-bind="gridOptions"> </vxe-grid>
  </div>
</template>
<script>
import {
  logList,
  getAllMenu,
  menuAdd,
  menuDel,
  menuUpdate,
} from "@/requests/api";
import { VXETable } from "vxe-table";
import XEUtils from "xe-utils";
import { ref } from 'vue'
const xGrid = ref()
export default {
  name: "",
  mounted() {
    console.log(this.$refs.xGrid);
  },
  created() {
    getAllMenu({})
      .then((res) => {
        this.menus = res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  data() {
    return {
      selectRow: true,
      menus: [],
      showEdit: false,
      loadding: false,
      formData: {
        ip: "",
        addr: "",
        desc: "",
        agent: "",
        status: 1,
      },
      formRules: {
        name: [
          { required: true, message: "请输入名称" },
          { min: 2, max: 6, message: "长度在 2 到 6 个字符" },
        ],
      },
      gridOptions: {
        border: true,
        showHeaderOverflow: true,
        showOverflow: true,
        keepSource: true,
        id: "menu_list_table",
        height: 700,
        rowConfig: {
          keyField: "id",
          isHover: true,
        },
        columnConfig: {
          resizable: true,
        },
        customConfig: {
          storage: true,
          checkMethod({ column }) {
            if (["create_time", "update_time"].includes(column.field)) {
              return false;
            }
            return true;
          },
        },
        printConfig: {
          columns: [
            { field: "name" },
            { field: "path" },
            { field: "link" },
            { field: "pid" },
            { field: "icon" },
            { field: "status" },
            { field: "order" },
            { field: "desc" },
          ],
        },
        sortConfig: {
          trigger: "cell",
          remote: true,
        },
        filterConfig: {
          remote: true,
        },
        pagerConfig: {
          pageSize: 10,
          pageSizes: [5, 10, 15, 20, 50, 100, 200, 500, 1000],
        },
        formConfig: {
          titleWidth: 100,
          titleAlign: "right",
          items: [
            {
              field: "name",
              title: "名称",
              titlePrefix: {
                message: "菜单名称检索",
                icon: "vxe-icon-question-circle-fill",
              },
              itemRender: {
                name: "$input",
                props: { placeholder: "请输入名称" },
              },
            },
            {
              field: "path",
              title: "路由",
              itemRender: {
                name: "$input",
                props: { placeholder: "请输入路由地址" },
              },
            },

            {
              align: "center",
              // collapseNode: true,
              itemRender: {
                name: "$buttons",
                children: [
                  {
                    props: {
                      type: "submit",
                      content: "搜索",
                      status: "primary",
                    },
                  },
                  { props: { type: "reset", content: "清空" } },
                ],
              },
            },
          ],
        },
        toolbarConfig: {
          slots: {
            //   buttons: "toolbar_buttons",
          },
          refresh: true,
          import: true,
          export: true,
          print: true,
          zoom: true,
          custom: true,
        },
        proxyConfig: {
          seq: true, // 启用动态序号代理，每一页的序号会根据当前页数变化
          sort: true, // 启用排序代理，当点击排序时会自动触发 query 行为
          filter: true, // 启用筛选代理，当点击筛选时会自动触发 query 行为
          form: true, // 启用表单代理，当点击表单提交按钮时会自动触发 reload 行为
          // 对应响应结果 { result: [], page: { total: 100 } }
          props: {
            result: "data", // 配置响应结果列表字段
            total: "total", // 配置响应结果总页数字段
          },
          // 只接收Promise，具体实现自由发挥
          ajax: {
            // 当点击工具栏查询按钮或者手动提交指令 query或reload 时会被触发
            query: ({ page, sorts, filters, form }) => {
              const queryParams = Object.assign({}, form);
              // 处理排序条件
              const firstSort = sorts[0];
              if (firstSort) {
                queryParams.sort = firstSort.field;
                queryParams.order = firstSort.order;
              }
              // 处理筛选条件
              filters.forEach(({ field, values }) => {
                queryParams[field] = values.join(",");
              });
              queryParams.page = page.currentPage;
              queryParams.limit = page.pageSize;
              return logList(queryParams);
            },
            // 当点击工具栏删除按钮或者手动提交指令 delete 时会被触发
            delete: ({ body }) => {
              console.log(body);

              return;
            },
            // 当点击工具栏保存按钮或者手动提交指令 save 时会被触发
            save: ({ body }) => {
              console.log(body);
              return;
            },
            edit: ({ id }) => {
              console.log(id);
            },
          },
        },
        columns: [
          { type: "checkbox", title: "ID" },
          {
            field: "ip",
            sortable: false,
            title: "访问者ip",
            titlePrefix: { message: "后台页面菜单显示的汉字" },
          },

          {
            field: "addr",
            title: "位置",
            sortable: false,
            titlePrefix: {
              useHTML: true,
              content: "ip所在的地址",
            },
          },
          {
            field: "desc",
            title: "详情",
            sortable: false,
          },
          {
            field: "status",
            title: "结果",
            sortable: false,
            formatter({ cellValue }) {
              return cellValue === 1 ? "成功" : "失败";
            },
          },
          {
            field: "create_time",
            title: "创建时间",
            visible: false,
            sortable: true,
            formatter({ cellValue }) {
              return XEUtils.toDateString(cellValue, "yyyy-MM-dd HH:ss:mm");
            },
          },
          {
            field: "update_time",
            title: "最后修改时间",
            visible: false,
            sortable: true,
            formatter({ cellValue }) {
              return XEUtils.toDateString(cellValue, "yyyy-MM-dd HH:ss:mm");
            },
          },
          {
            field: "agent",
            title: "客户端信息",
            visible: true,
            titlePrefix: {
              useHTML: true,
              content: "客户端浏览器，操作系统信息",
            },
          },
        ],
        importConfig: {
          remote: true,
          types: ["xlsx"],
          modes: ["insert"],
          // 自定义服务端导入
          importMethod({ file }) {
            const $grid = xGrid.value;
            const formBody = new FormData();
            formBody.append("file", file);
            return fetch({})
              .then((response) => response.json())
              .then((data) => {
                VXETable.modal.message({
                  content: `成功导入 ${data.result.insertRows} 条记录！`,
                  status: "success",
                });
                // 导入完成，刷新表格
                $grid.commitProxy("query");
              })
              .catch(() => {
                VXETable.modal.message({
                  content: "导入失败，请检查数据是否正确！",
                  status: "error",
                });
              });
          },
        },
        exportConfig: {
          remote: true,
          types: ["xlsx"],
          modes: ["current", "selected", "all"],
          // 自定义服务端导出
          exportMethod({ options }) {
            const $grid = xGrid.value;
            const proxyInfo = $grid.getProxyInfo();
            // 传给服务端的参数
            const body = {
              filename: options.filename,
              sheetName: options.sheetName,
              isHeader: options.isHeader,
              original: options.original,
              mode: options.mode,
              pager: proxyInfo ? proxyInfo.pager : null,
              ids:
                options.mode === "selected"
                  ? options.data.map((item) => item.id)
                  : [],
              fields: options.columns.map((column) => {
                return {
                  field: column.field,
                  title: column.title,
                };
              }),
            };
            // 开始服务端导出
            return fetch({ body })
              .then((response) => response.json())
              .then((data) => {
                if (data.id) {
                  VXETable.modal.message({
                    content: "导出成功，开始下载",
                    status: "success",
                  });
                  // 读取路径，请求文件
                  fetch({}).then((response) => {
                    response.blob().then((blob) => {
                      // 开始下载
                      VXETable.saveFile({
                        filename: "导出数据",
                        type: "xlsx",
                        content: blob,
                      });
                    });
                  });
                }
              })
              .catch(() => {
                VXETable.modal.message({
                  content: "导出失败！",
                  status: "error",
                });
              });
          },
        },
        checkboxConfig: {
          labelField: "id",
          reserve: true,
          highlight: true,
          range: true,
        },
      },
    };
  },
  methods: {
    add() {
      this.selectRow = false;
      this.showEdit = true;
    },
    edit(row) {
      this.selectRow = true;
      this.formData = row;
      this.showEdit = true;
      delete this.formData.create_time;
      delete this.formData.update_time;
    },
    removeOne(row) {
      VXETable.modal
        .confirm({
          content: "删除后无法恢复！确定要删除？",
        })
        .then((res) => {
          if (res == "confirm") {
            this.notifyDelete([row.id]);
            this.$refs.xGrid.remove(row);
          } else {
            VXETable.modal.message({
              status: "info",
              content: "操作取消",
              top: 80,
            });
          }
        });
    },
    removeMany() {
      VXETable.modal
        .confirm({
          content: "删除后无法恢复！确定要删除多条记录？",
        })
        .then((res) => {
          if (res == "confirm") {
            this.notifyDelete(
              this.$refs.xGrid.getCheckboxRecords().map((x) => {
                return x.id;
              })
            );
            this.$refs.xGrid.removeCheckboxRow();
          } else {
            VXETable.modal.message({
              status: "info",
              content: "操作取消",
              top: 80,
            });
          }
        });
    },
    notifyDelete(ids) {
      menuDel({
        id: ids,
      }).then(() => {
        VXETable.modal.message({
          status: "success",
          content: "操作成功！",
          top: 80,
        });
      });
    },
    change(e, row) {
      console.log(e);
      menuUpdate({
        id: row.id,
        status: e.target.checked ? 1 : 0,
      })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    reset() {
      var default_Data = {
        name: "",
        path: "",
        link: "",
        pid: 0,
        icon: "fa-list-squares",
        order: 1,
        status: 1,
      };
      this.formData = default_Data;
    },
    submitEvent() {
      if (this.selectRow) {
        menuUpdate(this.formData).then((res) => {
          console.log(res);
          this.$refs.xGrid.reloadRow(this.formData);
          this.showEdit = false;
        });
      } else {
        menuAdd(this.formData)
          .then((res) => {
            console.log(res);
            this.showEdit = false;
            this.$router.go(0);
          })
          .catch((err) => {
            console.log(err);
          });
      }
      this.reset();
    },
    visibleMethod() { },
  },
};
</script>
<style>
/* .vxe-table--header {
    background-color: green;
    color: #fff;
  } */
</style>
